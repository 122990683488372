<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default: {}
      }
    },
    data() {
      return {
        showWriteComment: false,
        sending: false,
        firstIn: true,
        groupGuidChange: false,
        senceGuidChange: false,
        panoUpdae: false,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      noRouter() {
        return this.config.noRouter
      },
      routerDatas() {
        return this.config.routerDatas
      },
      autoSence() {
        if (this.config.autoSence == undefined) {
          return true
        }
        return this.config.autoSence
      },
      tour() {
        return this.publicData.tour || {}
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
    },
    watch: {
      tour(val, oldval) {
        if (val == oldval) {
          return
        }
        this.update(val)
      },
      senceGuid(val, oldval) {
        if (this.panoUpdae) {
          this.panoUpdae = false
          return
        }
        if (this.senceGuidChange) {
          this.senceGuidChange = false
          return
        }
        if (val == oldval) {
          return
        }
        this.update(null, val, null)
      },
      groupGuid(val, oldval) {
        if (this.groupGuidChange) {
          this.groupGuidChange = false
          return
        }
        if (val == oldval) {
          return
        }
        this.update(null, null, val)
      }
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          getgroup: this.getGroupItems,
          getPano: this.getPano,
          toPano: this.toPano,
          toGroup: this.toGroup,
          setFirstIn: this.setFirstIn,
          backToNomal: this.backToNomal,
        }
      })
      this.update(this.tour, this.senceGuid)

      var g = this.getGroup(this.groupGuid)
      if (g && g.type == 'Blog') {
        this.getFunc('renewSence')('blog')
      }
      if (g && g.type == 'Gallery') {
        this.getFunc('renewSence')('gallery')
      }
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      backToNomal() {
        this.getFunc({
          name: "renewSence",
          arg: "default"
        })()
        var senceGuid = null
        var groupGuid = null
        if (this.$route.query) {
          if (this.$route.query.senceId) {
            senceGuid = this.$route.query.senceId
          }
          if (this.$route.query.groupId) {
            groupGuid = this.$route.query.groupId
          }
        }
        console.log('backToNomal', null, senceGuid, groupGuid)
        this.update(null, senceGuid, groupGuid)
      },
      setFirstIn(b) {
        this.firstIn = b
      },
      getGroupItems(groupGuid, tour) {
        var gg = groupGuid || this.groupGuid
        var g = []
        var t = tour || this.tour
        for (var i in t.items) {
          var item = t.items[i]
          if (item.groupGUID == gg) {
            g.push(item)
          }
        }
        return g
      },
      getItemIndex(guid) {
        for (var i in this.tour.items) {
          if (this.tour.items[i].guid == guid) {
            return i
          }
        }
        return null
      },
      getGroup(groupGuid) {
        console.log('getGroup groups', this.tour.groups)
        for (var i in this.tour.groups) {
          if (this.tour.groups[i].guid == groupGuid) {
            return this.tour.groups[i]
          }
        }
      },
      getPano(senceGuid) {
        var s = senceGuid || this.senceGuid
        for (var i in this.tour.items) {
          if (this.tour.items[i].guid == s) {
            return this.tour.items[i]
          }
        }
      },
      isItemInGroup(guid, groupItems) {
        var gis = groupItems || this.groupItems
        for (var i in gis) {
          if (gis[i].guid == guid) {
            return true
          }
        }
        return false
      },
      toPano(guid, obj) {
        if (this.noRouter) {
          if (!guid) return
          var eig = guid
          var eii = this.getItemIndex(eig) || 0
          var pano = { ...this.itemToPano(this.tour, eii), ...obj }
          this.getFunc('changePublicData')({ senceGuid: guid, pano })
          this.panoUpdae = true
        } else {
          var rd = {}
          for (var i in this.routerDatas) {
            rd[this.routerDatas[i]] = this.$route.query[this.routerDatas[i]]
          }
          this.$router.push({ query: { senceId: guid, from: this.$route.query.from, ...rd, ...obj } })
        }
      },
      toGroup(guid, obj) {
        if (this.noRouter) {
          this.getFunc('changePublicData')({ senceGuid: guid })
        } else {
          var rd = {}
          for (var i in this.routerDatas) {
            rd[this.routerDatas[i]] = this.$route.query[this.routerDatas[i]]
          }
          this.$router.push({ query: { groupId: guid, from: this.$route.query.from, ...rd, ...obj } })
        }
      },
      update(tour, editItemGuid, groupGuid) {
        console.log('update', tour, editItemGuid, groupGuid)
        var t = tour || this.tour
        var eig = editItemGuid || this.editItemGuid || null
        var gg = groupGuid || this.groupGuid
        var eii = null
        var gis = []
        var p = {}
        if (editItemGuid) {
          eii = this.getItemIndex(eig)
          if (eii != null) {
            gg = t.items[eii].groupGUID
          }
        }
        if (groupGuid) {
          gis = this.getGroupItems(gg, t)
          if (!this.isItemInGroup(eig, gis) && gis[0]) {
            eig = gis[0].guid
          } else {
            eig = null
          }
        }
        if (!gg) {
          if (t.groups[0]) {
            gg = t.groups[0].guid
          }
        }
        if (this.hasGroup(t.groups)) {
          gis = this.getGroupItems(gg, t)
        } else {
          gis = t.items
        }
        if (!eig) {
          if (gis[0]) {
            eig = gis[0].guid
          }
        }
        eii = this.getItemIndex(eig)
        var g = this.getGroup(gg)
        if (eii !== null && (!g || g.type == 'Pano')) {
          p = this.itemToPano(t, eii)
        } else {
          //p = this.emityPano(t)
          p = null
        }
        console.log('eii', eii, 'eig', eig, 'p', p, 'g', g, 'gg', gg,)
        if (gg != this.groupGuid) {
          this.groupGuidChange = true
        }
        if (eig != this.senceGuid) {
          this.senceGuidChange = true
        }
        if (this.autoSence) {
          if (g && g.type == 'Blog') {
            this.getFunc('renewSence')('blog')
          }
          if (g && g.type == 'Gallery') {
            this.getFunc('renewSence')('gallery')
          }
          if (g && g.type == 'Pano') {
            if (this.getFunc({ name: 'isInVRMode', target: 'FullPano' })()) {
              this.getFunc('renewSence')('webVR')
            } else {
              this.getFunc('renewSence')('default')
            }
          }
        }
        this.getFunc('changePublicData')({
          pano: p,
          guid: t.guid,
          groupGuid: gg,
          //groupName: g.title,
          groupItems: gis,
          senceGuid: eig,
          $v: this.$v
        })
      },
      hasGroup(groups) {
        for (var i in groups) {
          if (groups[i].title) {
            return true
          }
        }
        return false
      },
      emityPano(tour) {
        var pano = {
          user: tour.user || { userName: '' },
          isPro: tour.isPro,
          links: tour.links,
          hotspots: [],
          logo: tour.logo,
          hideLogo: tour.hideLogo,
          hideCount: tour.hideCount,
          paid: tour.paid,
          praiseCount: tour.praiseCount,
          viewCount: tour.viewCount,
          shareCount: tour.shareCount,
          gpsLng: tour.gpsLng,
          gpsLat: tour.gpsLat,
        }
        if (tour.isPro) {
          pano.links = tour.links || []
        } else if (tour.links && tour.links.length > 0) {
          pano.links = [tour.links[0]]
        } else {
          pano.links = []
        }
        return pano
      },
      itemToPano(tour, index) {
        if (index >= tour.items.length || index < 0) {
          index = 0
        }
        var item = tour.items[index]
        var pano = item.linkItem
        pano.audio = item.audio
        pano.audioAutoPlay = item.audioAutoPlay
        pano.audioLoop = item.audioLoop
        pano.hotspots = item.hotspots
        pano.title = `${item.title}`//`${tour.title} - ${item.title}`
        pano.text = item.description
        pano.file = item.file
        if (tour.isPro) {
          pano.links = tour.links || []
        } else if (tour.links && tour.links.length > 0) {
          pano.links = [tour.links[0]]
        } else {
          pano.links = []
        }
        if (item.fov && item.fov != 0) {
          pano.fov = item.fov
        }
        pano.hLookAt = item.hLookAt
        pano.vLookAt = item.vLookAt
        if (item.vLookAtMin != 0 || item.vLookAtMax != 0) {
          pano.vLookAtMin = item.vLookAtMin
          pano.vLookAtMax = item.vLookAtMax
        }
        pano.architectural = item.architectural
        pano.distortion = item.distortion
        pano.pannini = item.pannini
        pano.littlePlanetIntro = false
        pano.blend = tour.blend
        if (this.firstIn) {
          pano.littlePlanetIntro = tour.littlePlanetIntro
          this.firstIn = false
        }
        pano.weather = item.weather
        pano.postprocessing = item.postprocessing || pano.postprocessing
        pano.logo = tour.logo
        pano.hideAvatar = tour.hideAvatar
        pano.hideLogo = tour.hideLogo
        pano.hideCount = tour.hideCount
        pano.paid = tour.paid
        pano.isPro = tour.isPro
        pano.user = tour.user || { userName: '' }
        pano.praiseCount = tour.praiseCount
        pano.viewCount = tour.viewCount
        pano.shareCount = tour.shareCount
        pano.gpsLng = tour.gpsLng
        pano.gpsLat = tour.gpsLat

        for (var i in this.$route.query) {
          switch (i) {
            case 'view.fov':
              pano.fov = this.$route.query[i]
              break
            case 'view.vLookAt':
              pano.vlookat = this.$route.query[i]
              break
            case 'view.hLookAt':
              pano.hlookat = this.$route.query[i]
              break
            case 'view.architectural':
              pano.architectural = this.$route.query[i]
              break
            case 'view.pannini':
              pano.pannini = this.$route.query[i]
              break
            case 'view.distortion':
              pano.distortion = this.$route.query[i]
              break
            default:
              pano[i] = this.$route.query[i]
              break
          }
        }

        return pano
      }
    },
  }
</script>
<style scoped>
</style>
